import { Link, navigate } from "gatsby"
import React, { useState, useRef, useCallback } from "react"
import * as Sentry from "@sentry/gatsby"
import { MdCheckCircle, MdContentCopy, MdError } from "react-icons/md"
import Alert from "@material-ui/lab/Alert"
import BigNumber from "bignumber.js"
import { formatValue } from "./profile"
import { reportToSentry } from "."
import { statuses } from "./auth"
import { statuses as authStatuses } from "../hooks/auth"
import { useAppState } from "../components/provider"
import firebaseApp, { functions } from "../configs/firebase"
import Layout from "../components/layout"
import Loader from "../components/loader"
import SEO from "../components/seo"
import step1Url from "../images/step1.png"
import step2Url from "../images/step2.png"
import step3Url from "../images/step3.png"

const HoldingVerification = () => {
  const {
    auth: { user, status: authStatus, profile = {} },
  } = useAppState()

  const copyElem = useRef(null)
  const [signature, setSignature] = useState("")
  const [payload, setResultPayload] = useState({})
  const [status, setStatus] = useState(statuses.initial)
  const [error, setError] = useState({})
  const hasError = !!error.code
  const isLoading =
    status === statuses.processing || authStatus === authStatuses.processing
  const [textInputNode, setInputNode] = useState(null)
  const textInput = useCallback(
    node => {
      setInputNode(node)

      if (authStatus === authStatuses.loggedIn) {
        if (!node) return

        const verificationStart = functions.httpsCallable("verificationStart")
        const messageToSign = sessionStorage.getItem("messageToSign")
        if (messageToSign) {
          node.value = messageToSign
          return
        }

        if (user) {
          verificationStart()
            .then(verificationResult => {
              firebaseApp.analytics().logEvent("verification_started")
              const newMessage = verificationResult.data.message
              node.value = newMessage
              sessionStorage.setItem("messageToSign", newMessage)
            })
            .catch(err => {
              reportToSentry(err, {
                user: { id: user.uid },
              })
            })
        }
      } else if (authStatus === authStatuses.initial && user === null) {
        navigate("/")
      }
    },
    [user, authStatus]
  )

  const onCopyBuffer = () => {
    textInputNode.select()
    try {
      document.execCommand("copy")
      copyElem.current.innerHTML = "Copied!"
      setTimeout(() => {
        copyElem.current.innerHTML = "Copy"
      }, 2000)
    } catch (err) {
      console.error("Oops, unable to copy")
    }
  }

  const onSignatureChange = event => {
    const { value } = event.target
    setSignature(value)
  }

  const onFormSubmit = event => {
    event.preventDefault()

    let parsedSignature
    try {
      parsedSignature = JSON.parse(signature)
    } catch (err) {
      firebaseApp.analytics().logEvent("verification_issue")
      setStatus(statuses.failed)
      return
    }

    const verificationConfirm = functions.httpsCallable("verificationConfirm")
    const payload = { signature: parsedSignature }
    setError({})
    setStatus(statuses.processing)
    verificationConfirm(payload)
      .then(result => {
        firebaseApp.analytics().logEvent("verification_finished")
        if (BigNumber(payload.balance).gt(0))
          firebaseApp.analytics().logEvent("has_msp")
        setStatus(statuses.success)
        const { data } = result
        setResultPayload(data)
        sessionStorage.removeItem("messageToSign")
      })
      .catch(err => {
        firebaseApp.analytics().logEvent("verification_issue")
        if (err.code === "failed-precondition") {
          setError({ code: err.code, message: err.message })
          setStatus(statuses.initial)
        } else {
          reportToSentry(err, {
            user: { id: profile.uid },
          })
          setStatus(statuses.failed)
        }
      })
  }

  const renderFormTemplate = renderStatus => {
    switch (renderStatus) {
      case statuses.failed:
        return (
          <>
            <div className="iconContainer">
              <MdError size="4em" className="Icon failed" />
            </div>
            <p className="Text centered">
              We cannot verify your signature. Please, try to refresh the page
              and follow the procedure again. If it&apos;s still a problem
              we&apos;re glad to help on the Intercom chat.
            </p>
          </>
        )
      case statuses.success:
        return (
          <>
            <div className="iconContainer">
              <MdCheckCircle size="4em" className="Icon success" />
            </div>
            <p className="Text centered">Thank you for verification!</p>
            <p className="Text centered">
              We see you hold{" "}
              <strong>{formatValue(payload.balance)} MSP</strong> on{" "}
              <strong>{payload.address}</strong>.
            </p>
            <p className="Text centered">
              We put it to the watch list to keep the balance up to date.
            </p>
            <div className="iconContainer">
              <Link className="Btn primary" to="/profile/">
                Back to profile
              </Link>
            </div>
          </>
        )
      default:
        return (
          <form onSubmit={onFormSubmit}>
            <h3 className="Lead dark justify">
              3. Copy the result from MyEtherWallet and submit it on the form
              below.
            </h3>
            <img className="img" src={step3Url} alt="" />
            <div className={`Textfield widen ${hasError && "error"}`}>
              <textarea
                className="input"
                onChange={onSignatureChange}
                placeholder="Paste signed message here..."
                required
                rows="10"
                value={signature}
              />
            </div>
            {hasError && (
              <Alert severity="error" style={{ marginBottom: "1rem" }}>
                {error.message}
              </Alert>
            )}
            <button className="Btn primary" disabled={isLoading}>
              Submit
            </button>
            <Loader isLoading={isLoading} />
          </form>
        )
    }
  }

  return (
    <Layout>
      <SEO title="MSP holding verification" />
      <Sentry.ErrorBoundary
        beforeCapture={scope => {
          scope.setUser({ id: profile.uid })
        }}
      >
        <div className="Section">
          <div className="PanelContainer">
            <h1 className="Title">
              <Link className="link" to="/profile/">
                Stakeholder Profile
              </Link>
            </h1>
            <h2 className="Lead">{profile.email || "-"}</h2>
            <section className="Panel formFormat">
              <h3 className="Lead dark justify">
                1. Open MyEtherWallet and connect a wallet where you have MSP
                holdings.
              </h3>
              <p className="Text">
                If you're not familiar with MyEtherWallet, please check their
                guides on how to access a wallet{" "}
                <a
                  className="Link clean"
                  href="https://kb.myetherwallet.com/en/categories/"
                >
                  https://kb.myetherwallet.com/en/categories/
                </a>
              </p>
              <img className="img" src={step1Url} alt="" />
            </section>
            <section className="Panel formFormat">
              <h3 className="Lead dark justify">
                2. Copy the text below. Then on MyEtherWallet, choose{" "}
                <i>Message &gt; Sign</i> from the sidebar and paste the text to
                the text area. Click the <i>Sign</i> button to get the result.
              </h3>
              <div className="InlineForm">
                <div className="Textfield widen">
                  <input
                    className="input"
                    id="mspHolding"
                    placeholder="Loading..."
                    readOnly
                    ref={textInput}
                    required
                    type="text"
                  />
                </div>
                <button
                  className="Btn primary"
                  type="button"
                  onClick={onCopyBuffer}
                  disabled={isLoading}
                >
                  <MdContentCopy /> <span ref={copyElem}>Copy</span>
                </button>
              </div>
              <img className="img" src={step2Url} alt="" />
            </section>
            <section className="Panel formFormat">
              {renderFormTemplate(status)}
            </section>
          </div>
        </div>
      </Sentry.ErrorBoundary>
    </Layout>
  )
}

export default Sentry.withErrorBoundary(HoldingVerification, {
  feelback: "an error has occurred in auth page",
})
